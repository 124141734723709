export default {
    state: {
        menu: [
            { product: true, title: 'Withholding Tax', bgcolor: 'ccompliance' },
            // { button: true, title: 'Create Analysis', href: '/tds/ar#add' },
            // {
            //   title: 'Dashboard', icon: 'mdi mdi-view-dashboard-outline', href: '/pbc/dashboard',
            // },
            {
                title: 'AR Analysis', icon: 'mdi mdi-format-list-group', href: '/tds/ar',
            },
        ],
    },
    mutations: {
    },
    actions: {
    },
    getters: {

    },
}